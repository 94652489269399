/* body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.transparent-caret {
  caret-color: transparent; /* Change the caret (blinking cursor) color to red */
}

/* swiper default css removal */
.swiper-wrapper {
  min-height: 174px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100%;
}
.swiper-pagination-bullet {
  background-color: #cfd4ff;
  opacity: 0;
}
.swiper-pagination-bullet-active {
  background-color: #2136d4 !important;
  opacity: 0;
}

robot_font {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes custom-animate-pulse {
  0% {
    transform: scale(0.85);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.85);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.85);
  }
}

.custom-animate-pulse {
  animation: custom-animate-pulse 2s ease-in-out infinite;
}

.custom-blue-pulse {
  animation: 1.5s custom-pulse-blue-animation infinite ease-in-out;
}
.custom-golden-pulse {
  animation: 1.5s custom-pulse-golden-animation infinite ease-in-out;
}

.mirror-Animation {
  position: relative;
  overflow: hidden;
}

.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}

@keyframes custom-pulse-blue-animation {
  0% {
    box-shadow: 0 0 0 0 #2136d4;
  }

  100% {
    box-shadow: 0 0 0 15px #2136d400;
  }
}
@keyframes custom-pulse-golden-animation {
  0% {
    box-shadow: 0 0 0 0 #fdd400;
  }

  100% {
    box-shadow: 0 0 0 15px #fdd40000;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .blue-gradient {
    @apply bg-gradient-to-br bg-[#2136d4] from-blue-500 via-blue-600 to-blue-900 text-white;
  }
  .green-gradient {
    @apply bg-gradient-to-br bg-green-500 from-green-500 via-green-600 to-green-900 text-white;
  }
  .blue-gradient-to-bottom-right {
    @apply bg-gradient-to-r  from-blue-100 via-blue-50  to-white;
  }
  .partial-black-background {
    @apply fixed top-0 right-0 left-0 bottom-0 z-[1] bg-[#000000c8];
  }
  .side-input {
    @apply border-b outline-none focus:border-blue-500 w-11/12;
  }
  .input2 {
    @apply border border-[#e7e7e7] rounded-lg text-[.875rem] p-1;
  }
  .blog-input {
    @apply w-full;
  }

  .custom-blue-pulse {
    @apply h-4 w-4 bg-[#2136d4] rounded-full;
  }
  .custom-golden-pulse {
    @apply h-4 w-4 bg-[#fdd400] rounded-full;
  }
  .red-gradient {
    @apply bg-gradient-to-br bg-red-500 from-red-500 via-red-600 to-red-900 text-white;
  }

  .primary {
    @apply bg-blue-500 px-12 rounded-[4px] border-none w-6/12 hover:bg-blue-500;
  }

  .secondary {
    @apply rounded-[4px] border-none w-6/12 bg-gray-100 hover:text-white hover:bg-none text-black/80;
  }

  .modal-action {
    @apply w-full;
  }

  .btn-primary {
    @apply bg-[#2136D4] hover:bg-[#2136D4];
  }

  .btn-secondary {
    @apply text-[#2136D4] border-[#2136D4] border hover:bg-transparent hover:border-[#2136D4] bg-white;
  }

  .btn-basic {
    @apply bg-transparent border border-gray-500 text-gray-500 hover:bg-transparent hover:border-gray-500;
  }

  .btn {
    @apply rounded-[4px] text-sm font-normal capitalize;
  }

  .btn-payment {
    @apply bg-[#2136D4] text-base text-center py-1 hover:bg-[#2136D4] text-white border-0 font-semibold tracking-widest w-full;
  }

  .grid-container {
    @apply grid grid-cols-3;
  }

  .flex-item {
    @apply flex gap-4 items-center;
  }

  .nav-link {
    @apply text-black hover:text-black focus:text-black hover:no-underline focus:no-underline text-xl;
  }

  .sidenav-link {
    @apply my-1 hover:bg-gray-100 w-full px-3 cursor-pointer no-underline hover:no-underline focus:no-underline py-1 text-black;
  }

  .grid-container {
    @apply grid grid-cols-3;
  }
  .flex-item {
    @apply flex gap-4 items-center;
  }
  .filled {
    @apply text-[#2136D4];
  }

  .star {
    @apply h-8 w-8;
  }

  .empty {
    @apply text-gray-300;
  }
}
