@keyframes scaleAndTranslate {
  0% {
    transform: translateY(20px) scale(0.8);
    text-decoration: none;
    color: #2136d4;
  }
  50% {
    transform: translateY(0px) scale(1);
    text-decoration: none;
    color: #2136d4;
  }

  65% {
    transform: translateY(0px) scale(1);
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.647);
  }

  100% {
    transform: translateY(0px) scale(1);
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.647);
  }
}

.scale-translate-animation {
  animation: scaleAndTranslate 1.5s ease-in-out infinite forwards;
}

@keyframes drop-icon-animation-keyframe {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.drop-icon-animation {
  animation: drop-icon-animation-keyframe 1.5s ease-in-out infinite forwards;
}
